import React from 'react';

const ErrorPage = props => {
  return (
    <div className="container">
      <div className="row">
        <div id="primary">
          <div id="content" role="main">
            <article
              id="post-0"
              className="post error404 not-found col-12 col-md-12"
            >
              <header className="entry-header">
                <h1 className="entry-title">
                  Okay, you probably can’t tell from my stunning HD complexion
                  but I am blushing like a fire engine!
                </h1>
              </header>

              <div className="entry-content">
                <p>It seems we can’t find what you’re looking for.</p>
                <a href="/">Return home</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

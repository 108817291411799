import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeadTags from '../components/HeadTags';
import ErrorPage from '../components/ErrorPage';

//! CSS
import '../styles/style.css';
import '../styles/new.css';
import '../styles/revamp.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeadTags
      title={`ChatBots | Customer Service Conversational AI Chatbots `}
      content={{ type: 'article', url: '404' }}
      content={{
        type: 'website',
        description:
          'Automate your customer support and engagement with Conversational AI chatbots, used by leading banks, insurance companies and fintechs globally. No technical skills required.'
      }}
    />
    <ErrorPage />
  </Layout>
);

export default NotFoundPage;
